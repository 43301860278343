<template>
  <div class="payment-methods">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="payment-methods__item"
    >
      <app-img
        v-bind="item"
        fit="cover"
        class="payment-methods__image"
      />
    </div>

    <div class="payment-methods__item payment-methods__cash">
      <ui-icon
        :icon="iconWallet"
        class="payment-methods__cash-icon"
      />

      {{ t('translate.cashPayment') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import imageVisa from 'assets/img/payment-methods/visa.svg?url'
import imageMastercard from 'assets/img/payment-methods/mastercard.svg?url'
import imageApplePay from 'assets/img/payment-methods/apple-pay.svg?url'
import imageGooglePay from 'assets/img/payment-methods/google-pay.svg?url'
import iconWallet from 'assets/icons/light/wallet.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import { useI18n } from 'src/composables/useI18n'

const { t } = useI18n()

const items = [
  {
    alt: 'Visa',
    src: imageVisa
  },
  {
    alt: 'Mastercard',
    src: imageMastercard
  },
  {
    alt: 'ApplePay',
    src: imageApplePay
  },
  {
    alt: 'GooglePay',
    src: imageGooglePay
  }
]
</script>

<style lang="scss" scoped>
.payment-methods {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &__item {
    height: 40px;
    aspect-ratio: 1.4;
    position: relative;
    border-radius: var(--global-border-radius);
    overflow: hidden;
    color: var(--color-dark);
    background-color: var(--color-light);
    border: 1px solid var(--theme-color-separator);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__cash {
    display: flex;
    align-items: center;
    gap: 8px;
    aspect-ratio: initial;
    padding: 0 10px;
  }

  &__cash-icon {
    font-size: 18px;
  }
}
</style>
